<template>
    <section class="banner" style="background-image: url('assets/images/s1.jpg');">
    <div class="container">
        <div class="row">
           
            <div class="col-md-6">

                <div style="background-color: #800; padding: 20px; border-radius: 20px;">
                    <h1 style="color:#fff; font-size: 8vw; font-weight: 800;">Sizemore Taxi</h1>
                <p style="color:#fff; font-size: 30px;">Always there,whenever you are ready</p>

                <div>
                    <router-link to="/login" class="btn theme-btn-3">Take a Ride</router-link>
                </div>
                </div>

            </div>
            
        </div>



        



    </div> 
    </section>


    <section>

        <div class="container">

            <div class="row">
           
           <div class="col-md-12" style="text-align: center; padding-bottom: 30px; padding-top: 60px;">
               <h1 style="font-size: 50px; font-weight: 800;">Who We Are</h1>
           </div>


           <div class="col-md-2"></div>
           <div class="col-md-8" style="text-align: center; padding-bottom: 30px;">
               <p>
                   Welcome to Sizemore Taxi, your go-to transport provider that provides convenience, reliability, and simplicity. At Sizemore Taxi, we take great satisfaction in providing excellent taxi and cab services to meet your needs for public transportation. We've got you covered whether you're going to the airport, navigating the city, or just need a ride virtually anywhere.
               </p>


               <div>
                <router-link to="/about" class="btn theme-btn btn-lg">Learn More</router-link>
               </div>


           </div>
           <div class="col-md-2"></div>
           
       </div>

        </div>

    </section>



    <section style="padding-top: 50px;">

<div class="container">

    <div class="row">
   
   <div class="col-md-4" style="text-align: center; padding-top: 200px;">
    <h3 style="color: #800;">phone</h3>
    <h1 style="font-size: 50px; font-weight: 800;">Accessibility</h1>
    <p>You can access the application from any mobile device via www.sizemoretaxi.com and make your order</p>
    <router-link to="/login" class="btn theme-btn-3 btn-lg">Take a Ride</router-link>
   </div>
   <div class="col-md-4" style="text-align: center; padding-bottom: 30px;">
       
    <img src="assets/images/phone.png" style="width: 100%;">


   </div>
   <div class="col-md-12" style="text-align: center; padding-top: 100px; background-color: #1a1a1a; border-radius: 20px; padding-bottom: 100px; ">
    <h3 style="color: #800;">pc</h3>
    <h1 style="font-size: 50px; font-weight: 800; color: grey;">Accessibility</h1>
    <p style="color: lightgrey;">You can access the application from any pc via www.sizemoretaxi.com and make your order</p>
    <router-link to="/login" class="btn theme-btn-3 btn-lg">Take a Ride</router-link>
   </div>
   
</div>

</div>

</section>







</template>

<script>
import axios from 'axios'
export default {
    name : 'LandingPage',

   

}
    


</script>

<style scoped>
.banner{
    padding-top: 100px;
    padding-bottom: 100px;
    background-size: cover;
    background-position: left;
}
</style>