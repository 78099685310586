<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
                <div style="text-align: center;" class="shadow-lg p-3 mb-5 bg-body rounded">

                    <div style="border: solid 1px #800; padding: 2px; border-radius: 5px; background-color: #A75E5E; color: #fff;">
                        <p>Do not leave or refresh this page.We are gathering more information about your trip</p>
                    </div>

                    <div>
                        <img src="assets/images/loader-2.gif" alt="" style="height: 100px;">
                    </div>
                    <h5>Order successful!</h5>
                    <h4>Your ride will be at your doorstep within 30 minutes</h4>

                    <div style="padding-top: 25px;">
                        <router-link to="/" class="btn theme-btn-2">Close</router-link>
                        <router-link to="/" class="btn theme-btn">Call Office</router-link>
                      
                    </div>
                    <div style="padding-top: 20px;">
                        <p>{{ countdown }} minutes remaining</p>
                        <p class="text-success">Your car is... {{ reg_number }} {{ car_model }}  </p>
                        <h6> {{ price }} </h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'ConfirmOrder',
    data() {
        return {
            countdown : 30,
            pickup_location : localStorage.getItem('pickup_location'),
            destination : localStorage.getItem('destination'),
            price : 'Calculating price...',
            car_type : '',
            car_model : '',
            reg_number : '',
        }
    },
    methods : {
        timer(){
        setInterval(() => {
        this.countdown--  
        if(this.countdown==0){
            this.$router.push('/')
        }
        }, 60000);
        
        },

        async submit_details(){
            const res = await axios.post(this.$store.state.api_url+'api/make-order',{
                pickup_location : this.pickup_location,
                destination : this.destination,
                first_name : localStorage.getItem('first_name'),
                last_name : localStorage.getItem('last_name'),
                email : localStorage.getItem('email'),
                phone : localStorage.getItem('phone'),
                user_id : localStorage.getItem('user_id'),
                car_type : localStorage.getItem('car_type'),
                token : localStorage.getItem('token'),
                passengers : localStorage.getItem('passengers'),
                luggages : localStorage.getItem('luggages'),
                pickup_time : localStorage.getItem('pickup_time'),
                women_only : localStorage.getItem('women_only')
            }).then(function(response){
                console.log(response.data)
            }).catch(function(error){
                console.log(error)
            })
        },

        async check_pending_orders(){
            const res = await axios.post(this.$store.state.api_url+'api/check-pending-orders',{
                user_id : localStorage.getItem('user_id')
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 

            if(res.length>0){
                console.log('Current ride not completed')
            }else{
                this.submit_details() 
                
            }
        },

        play_sound(){
        var audio = new Audio('assets/sounds/alert.wav')
        audio.play()
        }
    },
    created (){
        this.timer()
        this.check_pending_orders()

         setInterval(async () => {

            const res = await axios.post(this.$store.state.api_url+'api/calculate-price',{
                user_id : localStorage.getItem('user_id')
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })   
            
           this.price = res
         
            
        }, 6000);



setInterval(async () => {

const res = await axios.post(this.$store.state.api_url+'api/get-order-car',{
    user_id : localStorage.getItem('user_id'),
    token : localStorage.getItem('token')
}).then(function(response){
    return response.data
}).catch(function(error){
    console.log(error)
})   

res.forEach(data => {
    this.reg_number = data.reg_number
    this.car_model = data.car_model
    this.car_type = data.car_type
 

});

}, 8000);


    }
}
    


</script>

<style scoped>

</style>