<template>
    <section>
    <div class="container">
        <div class="row">
           
            <div class="col-md-12" style="text-align: center; padding-bottom: 30px; padding-top: 30px;">
                <h1 style="font-size: 50px; font-weight: 800;">Who We Are</h1>
            </div>


            <div class="col-md-2"></div>
            <div class="col-md-8" style="text-align: center; padding-bottom: 30px;">
                <p>
                    Welcome to Sizemore Taxi, your go-to transport provider that provides convenience, reliability, and simplicity. At Sizemore Taxi, we take great satisfaction in providing excellent taxi and cab services to meet your needs for public transportation. We've got you covered whether you're going to the airport, navigating the city, or just need a ride virtually anywhere.
                </p>
            </div>
            <div class="col-md-2"></div>
            
        </div>

        

        <div class="row shadow-lg">



            <div class="col-md-6" style="background-image: url('assets/images/s2.jpg'); min-height: 400px; background-size: cover;">
             
            </div>

            <div class="col-md-6" style="padding-top: 60px; background-color: #800; color: lightgrey;">

              <h1>Reservation</h1>
              <p>
                Our easy-to-use reservation system is one of the distinguishing qualities that make Sizemore Taxi stand out. We encourage our customers to make reservations at least 30 minutes and preferably long before the time they prefer to be picked up because we recognize how important it is to prepare ahead. By doing this, we can effectively meet your transportation demands and guarantee a smooth experience.
              </p>

            </div>

        </div>




        <div style="text-align: center; padding-top: 80px; padding-bottom: 60px;">
            <h1>Fleet</h1>
        </div>


        <div class="row">


            <div class="col-md-6">

               <div style="background-color: #E8E7E6; padding: 20px; border-radius: 10px;" class="shadow-lg">
                <p>
                    Our fleet comprises of just one type of car, specifically chosen to give our passengers a relaxing and delightful trip. Your safety and comfort are our top priorities, and to improve your traveling experience, we outfit our well-kept vehicles with the latest conveniences. No matter if you're going solo or in a group, our vehicles will suit your requirements. For each class, we have a single type of vehicle in our fleet. 
                </p>
               </div>

            </div>



            <div class="col-md-6">

<div style="background-color: #E8E7E6; padding: 20px; border-radius: 10px;" class="shadow-lg">
 <p>
    Suzuki Swift for the refined cab.Nissan Sylphy for an executive sedan. For a Toyota Noah, an all-purpose XL. All have been carefully chosen to give our customers a relaxing and delightful experience. Your safety and comfort are our top priorities, and to improve your traveling experience, we outfit our well-kept vehicles with the latest conveniences. No matter if you're going solo or in a group, our vehicles can meet your requirements.
 </p>
</div>

</div>



        </div>






        <div class="row shadow-lg" style="margin-top: 80px;">



<div class="col-md-6" style="background-image: url('assets/images/map-3.jpg'); min-height: 300px; background-size: cover;">
 
</div>

<div class="col-md-6" style="padding-top: 60px; background-color: #1a1a1a; color: lightgrey; padding-bottom: 50px;">

  <h1>Routes</h1>
  <p>
    Sizemore Taxi provides flexibility in terms of routes. Because we are aware that every passenger has distinct preferences and needs, we provide several routes to get you where you need to go. Our knowledgeable drivers will get you to your destination quickly and without difficulty because they are familiar with the city's routes and traffic patterns.
  </p>

</div>

</div>




<div class="row">

   
    <div class="col-md-12" style="text-align: center; padding-top: 60px;">

        <div style="padding-bottom: 30px;">
            <h1>Fixed Rates</h1>
        </div>

        <p>
            Our service is built on transparency, which is why we established fixed cab rates. When you pick Sizemore Taxi, you will be confident that there won't be any unexpected costs or taxes. Given the exceptional level of service we offer, our pricing is competitive and gives substantial value.
You can count on us at Sizemore Taxi to be available. We are aware that transportation demands can occur day or night, at any moment. We endeavor relentlessly to be available for you anytime you need us
</p>

<p>
     
owing to this. To make sure that our services are readily available and trustworthy, our committed staff works around the clock.
For a hassle-free smooth transportation experience, consider Sizemore Taxi. We are dedicated to exceeding your expectations with outstanding customer service. Grab a seat, unwind, and let us handle all of your travel arrangements.
        </p>
    </div>
  

</div>




    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'How',

   

}
    


</script>

<style scoped>
.steps{
border: solid 2px #F1F1F1;
padding: 20px;
color: #800;
margin-top: 10px;
}
</style>