<template>

    <!-- header -->

    <div class="container-fluid" style="background: #1a1a1a; padding: 10px;">

      <div class="row">
        <div class="col-md-6">
          <router-link to="/about" class="btn text-danger  btn-sm">About Us</router-link>
          <router-link to="" class="btn text-danger  btn-sm">Terms & Conditions</router-link>
        </div>

      </div>

    </div>

<nav class="header-style navbar navbar-expand-sm navbar-light shadow-lg">
  <div class="container-fluid">

    <a class="navbar-brand logo" href="javascript:void(0)">
      
      <img src="assets/images/logo-2.png" class="image-design">

    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="mynavbar">
      <ul class="navbar-nav mx-auto">

        <li class="nav-item">
          <router-link class="nav-link" to="/">Home</router-link>
        </li> 

        <li class="nav-item">
          <router-link class="nav-link" to="/how">How It Works</router-link>
        </li> 

        <li class="nav-item" v-if="$store.state.user_type">
          <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
        </li> 

        <li class="nav-item" v-if="$store.state.logged_in">
          <router-link class="nav-link" to="/home">Make Trip</router-link>
        </li> 

        <li class="nav-item" v-if="$store.state.user_type">
          <router-link class="nav-link" to="/trips">Trips</router-link>
        </li> 

        <li class="nav-item" v-if="$store.state.user_type">
          <router-link class="nav-link" to="/cars">Cars</router-link>
        </li> 

        <li class="nav-item" v-if="$store.state.logged_in">
          <router-link class="nav-link" to="/my-trips">My Trips</router-link>
        
        </li>  

        <li class="nav-item" v-if="$store.state.user_type">
          <router-link class="nav-link" to="/accounts">Accounts</router-link>
        </li> 

  
      </ul>

      <form class="d-flex">

        <a href="tel:0110301401" class="btn theme-btn" style="margin-right:10px;">Contact Us</a>

        <router-link to="/login" class="btn theme-btn" style="margin-right:10px;" v-if="!$store.state.logged_in">Login</router-link>

        <router-link to="/register" class="btn theme-btn" style="margin-right:10px;" v-if="!$store.state.logged_in">Register</router-link>

        <button v-if="$store.state.logged_in" class="btn theme-btn" @click="log_out()">Log Out</button>
       
      </form>
    </div>
  </div>
</nav>


</template>


<script>
export default {
  name : 'Header',

  data (){
    return {
      logged_in : this.$store.state.logged_in
    }
  },

  created (){
    
  },
  methods : {
    log_out(){
      localStorage.clear()
      this.$store.state.user_type = localStorage.getItem('user_type')
      this.$store.state.logged_in = localStorage.getItem('logged_in'),
      this.$router.push('login')
    }
  }

}
</script>


<style>
.image-design{
    height: 60px;
}
</style>